<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <h1 v-if="user.id">{{ user.person.first_name }} {{ user.person.last_name }}'s Account Activity</h1>
        </b-skeleton-wrapper>
        <login-info
            class="mt-4"
            :id="id"
            :get-user="true"
            @fetched="setUser"
        ></login-info>
    </b-container>
</template>
<script>
import LoginInfo from './table/info/LoginInfo.vue';

export default {
    props: ["id"],
    components: { LoginInfo },
    data() {
        return {
            loading : true,
            user    : {}
        };
    },
    methods: {
        setUser(data) {
            this.user    = data.user;
            this.loading = false;
        }
    }
}
</script>